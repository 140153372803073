<template>
  <v-container fluid :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
    <!-- BREADCRUMBS -->
    <v-row dense>
      <v-col cols="12">
        <v-breadcrumbs class="py-0" :class="{'pl-0': $vuetify.breakpoint.smAndUp}" :items="breadcrumbs">
          <v-icon slot="divider" class="xsmall">fas fa-chevron-right</v-icon>
          <template slot="item" slot-scope="props">
            <v-btn
              color="color3"
              class="pa-0 ma-0 xsmall"
              style="min-width: 0"
              text
              :to="props.item.to"
              exact
              small
              :disabled="props.item.disabled"
            >{{props.item.text}}</v-btn>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <!-- MAIN CONTENT -->
    <v-row dense>
      <v-col cols="12">
        <v-card color="grey lighten-3">
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>{{tournament.name}} Court {{court}}</v-toolbar-title>
          </v-toolbar>
          <v-container fluid>
            <v-row dense>
              <v-expand-transition>
                <v-col cols="12" class="text-center py-13" v-if="loading">
                  <v-progress-circular indeterminate color="color3"></v-progress-circular>
                </v-col>
              </v-expand-transition>
              <v-expand-transition>
                <v-col cols="12" class="text-center py-13 title" v-if="noMatches">
                  There are no published matches for this event.
                </v-col>
              </v-expand-transition>
            </v-row>
            <v-row dense v-for="(match, i) in matches" :key="`${match.m.number}-${match.m.id}-${i}`">
              <v-col cols="12">
                <v-card :ref="`${match.m.number}-${match.m.id}`">
                  <v-card-title class="pb-0">
                    {{match.titleArray | pipeArray}}
                  </v-card-title>
                  <full-match-card
                    style="width: 100%"
                    :matchIn="match.m"
                    :pool="match.ref.pool"
                    :bracket="match.ref.bracket"
                    :bracketCard="!!match.ref.bracket"
                    :division="match.ref.division"
                    :round="match.ref.round"
                    :flat="true"
                    :hideLive="false"
                  ></full-match-card>
                  <div v-if="!match.ref.division.isAdults || true" class="py-3 text-center">
                    <player-cards-list-dialog
                      ref="cards"
                      :match="match.m"
                      :text="true"
                    ></player-cards-list-dialog>
                    <v-btn
                      color="color3"
                      text
                      v-if="match.route"
                      small
                      :to="match.route.to"
                      :class="{ 'mt-3': $vuetify.breakpoint.xsOnly}"
                    >
                      <v-icon class="mr-3">fas fa-caret-right</v-icon>
                      {{match.route.text}}
                    </v-btn>
                  </div>
                </v-card>
                <v-divider class="my-9"></v-divider>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-dialog
        v-model="dialog"
        scrollable
        max-width="500px"
        transition="dialog-transition"
        v-if="courts && courts.length"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            color="color3 color3Text--text"
            fab fixed bottom right
            v-on="on"
          >
            <v-icon>fas fa-search</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-toolbar color="color2 color2Text--text">
            <v-toolbar-title>Select A Court</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-autocomplete
              v-model="newCourt"
              :items="courts"
            ></v-autocomplete>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-fab-transition>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import FullMatchCard from '@/components/Tournament/Match/FullMatchCard'
import PlayerCardsListDialog from '@/components/Player/PlayerCardsListDialog'
import moment from 'moment'
import { firstBy } from 'thenby'

export default {
  props: ['court'],
  data () {
    return {
      day: null,
      prev: false,
      up: false,
      dialog: false,
      newCourt: null
    }
  },
  computed: {
    ...mapGetters(['tournament', 'view', 'isLiveScoring', 'matchProps']),
    breadcrumbs () {
      return [
        {
          text: 'Tournament Home',
          to: { name: 'tournament-brochure' },
          exact: true,
          disabled: false
        },
        {
          text: `Court ${this.court}`,
          exact: true,
          disabled: true
        }
      ]
    },
    allMatches () {
      return this.tournament.matches
    },
    liveMatch () {
      return this.isLiveScoring && this.tournament.skinnyTimeline.find(f => f.m.id === this.matchProps.id)
    },
    addLive () {
      return this.liveMatch && !this.matches.find(f => f.m.id === this.liveMatch.m.id)
    },
    courts () {
      return [...new Set(this.tournament.matches.map(m => m.court).filter(f => !!f))].sort(firstBy(Number))
    },
    prevMatches () {
      return this.court && this.tournament.skinnyTimeline.filter(f => f.court === this.court).filter(f => f.m.startTime.isBefore(this.dates.from))
    },
    currentMatches () {
      return this.court && this.tournament.skinnyTimeline.filter(f => f.court === this.court).filter(f => f.m.startTime.isBetween(this.dates.from, this.dates.to))
    },
    upcomingMatches () {
      return this.court && this.tournament.skinnyTimeline.filter(f => f.court === this.court).filter(f => f.m.startTime.isAfter(this.dates.to))
    },
    matches () {
      if (this.tournament.props.includes('allMatches')) return this.all
      return this.currentMatches && this.currentMatches.length ? this.currentMatches : this.upcomingMatches && this.upcomingMatches.length ? this.upcomingMatches : this.prevMatches
    },
    all () {
      return this.court && this.tournament.skinnyTimeline.filter(f => f.court === this.court)
    },
    undone () {
      return this.matches && this.matches.find(f => !f.complete)
    },
    loading () {
      return !this.fullyHydrated
    },
    dates () {
      return {
        from: moment().subtract(12, 'hours'),
        to: moment().add(18, 'hours')
      }
    },
    fullyHydrated () {
      return this.tournament.fullyHydrated
    },
    noMatches () {
      return this.fullyHydrated && !this.tournament.matches.length
    }
  },
  methods: {
    scroll () {
      this.$nextTick(() => {
        if (this.undone) {
          const match = this.undone
          const target = this.$refs[`${match.m.number}-${match.m.id}`]
          if (target) {
            const t = target[0]
            if (t) {
              this.$vuetify.goTo(t, {
                duration: 333,
                offset: 133,
                easing: 'easeInOutCubic'
              })
            }
          }
        }
      })
    }
  },
  mounted () {
    this.newCourt = this.court
  },
  watch: {
    // loading: 'scroll',
    // undone: 'scroll',
    fullyHydrated: 'scroll',
    dialog: function (val) {
      if (val) this.newCourt = this.court
    },
    newCourt: function (n) {
      if (n && n !== this.court) {
        this.$router.push({ name: 'court-timeline', params: { tournamentId: this.tournament.id, court: n } })
        this.dialog = false
        this.newCourt = null
      }
    }
  },
  components: {
    FullMatchCard,
    PlayerCardsListDialog
  }
}
</script>
